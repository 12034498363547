<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Product Serial Configurator</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="loaders.form"
            style="margin-left: 10px;"
        ></v-progress-circular>
        <v-btn class="ml-2" small color="success" :loading="loaders.saveAll" :disabled="loaders.form" @click="saveAll">Save All</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3">
        <v-form :disabled="loaders.form">
          <h3>Products</h3>
          <v-checkbox hide-details label="Show On Products?" v-model="data.globals.productSerialShowOnProducts" outlined dense/>
          <v-checkbox hide-details label="Create On Products?" v-model="data.globals.productSerialCreateOnProducts" outlined dense/>
          <br>
          <h3>Orders</h3>
          <v-checkbox hide-details label="Show On Order?" v-model="data.globals.productSerialShowOnOrder" outlined dense/>
          <v-checkbox hide-details label="Create On Order?" v-model="data.globals.productSerialCreateOnOrder" outlined dense/>
          <br>
          <v-checkbox hide-details label="Allow Serials Post Pickup Sale?" v-model="data.globals.allowSerialsPostPickupSale" outlined dense/>
          <v-checkbox hide-details label="Allow Serials Post Delivery Sale?" v-model="data.globals.allowSerialsPostDeliverySale" outlined dense/>
          <br>
          <h3>Inventory Transfer</h3>
          <v-checkbox hide-details label="Show On InventoryTransfer?" v-model="data.globals.productSerialShowOnInventoryTransfer" outlined dense/>
          <v-checkbox hide-details label="Create On InventoryTransfer?" v-model="data.globals.productSerialCreateOnInventoryTransfer" outlined dense/>
          <br>
          <h3>Receiving Reports</h3>
          <v-checkbox hide-details label="Show On ReceivingReport?" v-model="data.globals.productSerialShowOnReceivingReport" outlined dense/>
          <v-checkbox hide-details label="Create On ReceivingReport?" v-model="data.globals.productSerialCreateOnReceivingReport" outlined dense/>
          <br>
          <h3>Dispatch</h3>
          <v-checkbox hide-details label="Show On Dispatch?" v-model="data.globals.productSerialShowOnDispatch" outlined dense/>
          <v-checkbox hide-details label="Create On Dispatch?" v-model="data.globals.productSerialCreateOnDispatch" outlined dense/>
          <br>
          <h3>Returns</h3>
          <v-checkbox hide-details label="Show On Return?" v-model="data.globals.productSerialShowOnReturn" outlined dense/>
          <v-checkbox hide-details label="Create On Return?" v-model="data.globals.productSerialCreateOnReturn" outlined dense/>
          <br>
          <h3>IMEI</h3>
          <v-checkbox hide-details label="IMEI Mode? (Change Serial to IMEI)" v-model="data.globals.replaceProductSerialsWithIMEI" outlined dense/>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import utils from "../../plugins/helpers"

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: {
        globals: {}
      },
      validForm: false,

      loaders: {
        form: false,
        saveAll: false
      },

      taxTypes: [],

      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Hidden', value: 'hidden' },
        { text: 'Transactions', value: 'numTransactions' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try {
      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }
  },
  methods: {
    async fetchData(){
      try{
        this.loaders.form = true;

        let res = await axios.get(`${this.getEndpoint}/api/productSerialConfigurator`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data

        console.log(this.data)
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    async saveAll(){
      try{
        this.loaders.form = true;

        for(let key of Object.keys(this.data.globals)){
          if(typeof this.data.globals[key]!=='boolean' && !Array.isArray(this.data.globals[key])){
            if(this.data.globals[key]===null || this.data.globals[key]===undefined || this.data.globals[key].length===0){
              throw `Value ${key} cannot be empty`;
            }
          }
        }

        let res = await axios.put(`${this.getEndpoint}/api/productSerialConfigurator/saveAll`, {data: this.data})
        if(res.data.error) throw res.data.error

        this.snack(`All saved!`, "success");

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
